import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, breakpointsDown } from '../../styling/constants'
import { motion } from 'framer-motion'

import narrowGuide from "../../images/model-narrow.svg"
import wideGuide from "../../images/model-wide.svg"
import cartClose from "../../images/cart-close-dark.svg"
import { FormattedMessage } from 'react-intl'

const getAsset = (category) => {
  if (category.toLowerCase() === "wide") {
    return wideGuide
  } else if (category.toLowerCase() === "narrow") {
    return narrowGuide
  } else {
    return wideGuide
  }
}

const DarkenedBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`

const LargeContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
`

const SizeGuideWrapper = styled(motion.div)`
  position: fixed;
  background-color: ${colors.lightGray};

  padding: 4rem 5rem;

  @media ${breakpointsDown.tablet} {
    left: 0;
    right: 0;
  }

  @media ${breakpointsDown.mobile} {
    padding: 2.5rem 5vw;
  }
`

const CategoryHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media ${breakpointsDown.mobile} {
    flex-direction: column;
  }
`

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.gold};
  &:last-of-type {
    margin-left: 7rem;

    @media ${breakpointsDown.tablet} {
      margin-left: 3rem;
    }

    @media ${breakpointsDown.mobile} {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`

const CategoryText = styled.h4`
  font-size: 2rem;
  margin: 0;
`

const BeltContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`

const BeltText = styled.h5`
  border-bottom: 1px solid ${(props) => (props.active ? colors.gold : 'transparent')};
  margin: 0;
`

const BeltButton = styled.button`
  border: none;
  margin: 0 0.8rem 0 0.8rem;
  background: transparent;
  padding: 0;
  outline: none;
  color: ${colors.gold};
`

const GuideHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
`

const Guide = styled.img`
  width: 10rem;
  height: 19rem;
  object-fit: contain;

  @media ${breakpointsDown.tablet} {
    display: none;
  }
`

const Thead = styled.thead`
  font-weight: 900;

  td {
    border-bottom: 2px solid #999 !important;
  }
`

const TableHolder = styled.div`
  margin-left: 2rem;

  p {
    margin: 0;
  }

  @media ${breakpointsDown.tablet} {
    margin-left: 0;
  }
`

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 0.5rem;

  td, th {
    border: 1px solid #999;
    padding: 0.7rem;
    text-align: left;
    min-width: 4rem;

    @media ${breakpointsDown.tablet} {
      padding: 0.5rem;
    }

    @media ${breakpointsDown.mobile} {
      min-width: 3rem;
    }
  }

  td.wide-border {
    border-right: 2px solid #999 !important;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 1.4rem;
  height: 1.4rem;
`

const CloseImage = styled.img`
  width: 100%;
  height: 100%;
`

const SizeGuide = ({data, selected, onClose}) => {
  const [selectedBelt, setSelectedBelt] = useState({})
  const [selectedID, setSelectedID] = useState(selected)

  const getFlattenedArray = (object) => {
    return object.map(({ node }) => {
      return node.product
    }).flat()
  }

  useEffect(() => {
    const flattenedArray = getFlattenedArray(data)

    var selected = flattenedArray.filter((item) => {
      return item.contentful_id === selectedID
    })
    setSelectedBelt(selected[0])
  }, [selectedID])

  const clickedBelt = (e, beltID) => {
    e.preventDefault()
    setSelectedID(beltID)
  }

  return (
    <>
      <DarkenedBackground animate={{ opacity: 1 }} initial={{opacity: 0}} exit={{ opacity: 0 }} />
      <LargeContainer>
        <SizeGuideWrapper animate={{ opacity: 1, y: 0 }} initial={{opacity: 0, y: -200}} exit={{ opacity: 0, y: 200 }} transition={{
          type: 'spring',
          damping: 40,
          stiffness: 400,
        }}>
          <CloseButton onClick={(e) => onClose(e)}>
            <CloseImage src={cartClose} alt="Close button" />
          </CloseButton>
          <CategoryHolder>
            {data.map(({node}) => (
              <Category key={node.title.title}>
                <CategoryText>
                  {node.title.title}
                </CategoryText>
                <BeltContainer>
                  {node.product.map((product) => (
                    <BeltButton key={product.contentful_id} onClick={(e) => clickedBelt(e, product.contentful_id)}>
                      <BeltText active={product.contentful_id === selectedID}>
                        {product.productName.productName}
                      </BeltText>
                    </BeltButton>
                  ))}
                </BeltContainer>
              </Category>
            ))}
          </CategoryHolder>
          <GuideHolder>
            <Guide src={selectedBelt.category && getAsset(selectedBelt.category.title.title)} />
            <TableHolder>
              <Table>
                <Thead>
                  <tr>
                    <td className="wide-border">
                      <FormattedMessage id="sizeguide-size" />
                    </td>
                    <td>
                      <FormattedMessage id="sizeguide-perfect" />
                    </td>
                    <td>Min</td>
                    <td>Max</td>
                  </tr>
                </Thead>
                <tbody>
                  { selectedBelt.items && selectedBelt.items.map((item) => {
                    return (
                      <tr key={item.size}>
                        <td className="wide-border">{item.size}</td>
                        <td>{item.perfectFit}</td>
                        <td>{item.minFit}</td>
                        <td>{item.maxFit}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <p>
                <FormattedMessage id="sizeguide-centimeter" />
              </p>
            </TableHolder>
          </GuideHolder>
        </SizeGuideWrapper>
      </LargeContainer>
    </>
  )
}

export default SizeGuide
