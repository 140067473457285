import styled from 'styled-components'
import React from 'react'
import rightArrow from "../../images/rightImageArrow.svg"
import leftArrow from "../../images/leftImageArrow.svg"

const Arrow = styled.div`
  width: 3rem;
  z-index: 100;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArrowImage = styled.img`
  width: 1rem;
`

export const CarouselArrow = ({ direction, handleClick }) => {
  return (
    <Arrow onClick={handleClick} direction={direction}>
      <ArrowImage src={direction === 'right' ? rightArrow : leftArrow} />
    </Arrow>
  )
}