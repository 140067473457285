import styled from 'styled-components'
import React from 'react'
import { colors } from '../../styling/constants'

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: ${(props) => props.selected ? colors.darkGray : 'transparent'};
  border: 1px solid ${colors.darkGray};
  -webkit-transition: background-color 200ms ease-out;
  -ms-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
`

const DotsContainer = styled.div`
  width: ${(props) => props.width}px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CarouselDots = ({number, selected}) => {
  return (
    <DotsContainer width={number * 16}>
      {[...Array(number)].map((e, i) => <Dot selected={selected === i} key={i} />)}
    </DotsContainer>
  )
}

export default CarouselDots