import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import './productMarketing.scss'
import { FormattedMessage } from 'react-intl'

const ProductMarketing = ({first, second, third}) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "trees.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <BackgroundImage style={{backgroundPosition: ''}} className="product-marketing-container" fluid={ data.heroImage.childImageSharp.fluid }>
        <div className="marketing-content">
          <div className="marketing-item">
            <h4>
              {first.titleBranding}
            </h4>
            <p>{first.text.text}</p>
          </div>
          <div className="marketing-item">
            <h4>
              {second.titleBranding}
            </h4>
            <p>{second.text.text}</p>
          </div>
          <div className="marketing-item">
            <h4>
              {third.titleBranding}
            </h4>
            <p>{third.text.text}</p>
          </div>
        </div>
      </BackgroundImage>
    }
  />
)

export default ProductMarketing
