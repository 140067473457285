import React from 'react'
import { graphql } from 'gatsby'
import LocaleManager from '../components/LocaleManager'
import ProductComponent from '../components/product/ProductComponent';

const Product = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <ProductComponent data={data} locale={locale} />
  </LocaleManager>
)

export const query = graphql`
  query($id: String, $categoryId: String, $locale: String) {
    bottomBg: file(relativePath: { eq: "jamtland.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tuscany: file(relativePath: { eq: "tuscany.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketingOne: contentfulBranding(title: { eq: "Product-Shipping" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    marketingTwo: contentfulBranding(title: { eq: "Product-Fit" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    marketingThree: contentfulBranding(title: { eq: "Product-Sustainability" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    leatherMarketingOne: contentfulBranding(title: { eq: "Leather-Info-1" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    leatherMarketingTwo: contentfulBranding(title: { eq: "Leather-Info-2" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    product: contentfulProduct(id: { eq: $id }) {
      slug
      id
      price
      node_locale
      newPrice {
        sek
        eur
        usd
      }
      discount
      fullPrice {
        sek
        eur
        usd
      }
      outOfStock
      extraContent
      contentful_id
      category {
        title {
          title
        }
      }
      items {
        size
        quantity
        order
        sku
      }
      image {
        id
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 500) {
          ...GatsbyContentfulFixed
          src
        }
      }
      longDescription {
        longDescription
      }
      detailsMaterial {
        detailsMaterial
      }
      detailsColor {
        detailsColor
      }
      detailsWarranty {
        detailsWarranty
      }
      productName {
        productName
      }
    }
    sizeGuideData: allContentfulCategory(filter: {node_locale: {eq: $locale }}) {
      edges {
        node {
          title {
            title
          }
          product {
            productName {
              productName
            }
            category {
              title {
                title
              }
            }
            contentful_id
            items {
              size
              perfectFit
              minFit
              maxFit
            }
          }
        }
      }
    }
    relatedProducts: allContentfulProduct(limit: 3, filter: { category: {id: {eq: $categoryId } }, id: { ne: $id }, node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          price
          newPrice {
            sek
            eur
            usd
          }
          discount
          fullPrice {
            sek
            eur
            usd
          }
          outOfStock
          productDescription {
            productDescription
          }
          slug
          category {
            title { title }
          }
          image {
            fixed(width: 350) {
              ...GatsbyContentfulFixed
            }
          }
          productName {
            productName
          }
        }
      }
    }
  }
`;

export default Product
