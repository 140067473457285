import React from 'react'
import SEO from '../seo'
import ProductDetails from './productDetails'
import ProductMarketing from './productMarketing'
import ProductContent from './ProductContent'
import DoubleImage from './DoubleImage'
import ProductShowcase from '../productShowcase'
import ImageHeadline from '../home/ImageHeadline'
import { FormattedMessage } from 'react-intl'
import Context from '../Context'
import { getCurrency, getPrice } from '../../utilities/currencies'

const ProductComponent = ({ data: productData, locale }) => {
  const currency = (locale) => {
    if (locale == 'sv') {
      return 'sek'
    } else {
      return 'eur'
    }
  }

  return (
    <>
      <SEO 
        title={ productData.product.productName.productName } 
        description={productData.product.longDescription.longDescription} 
        lang={locale} 
        keywords={['belt', 'skärp', productData.product.productName.productName]} 
        structuredData={
          {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: productData.product.productName.productName,
            image: productData.product.image.map(image => image.fixed.src),
            description: productData.product.longDescription.longDescription,
            sku: productData.product.id,
            brand: {
              '@type': 'Brand',
              name: 'Belt of Sweden',
            },
            offers: {
              '@type': 'Offer',
              priceCurrency: currency(productData.product.node_locale).toUpperCase(),
              price: getPrice(productData.product.newPrice, locale, currency(productData.product.node_locale)).toString(),
              availability: productData.product.outOfStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock',
            },
          }
        }
      />
      <div className='content extra-top'>
        <ProductDetails
          locale={locale}
          outOfStock={productData.product.outOfStock}
          sizeGuideData={productData.sizeGuideData.edges}
          name={productData.product.productName.productName}
          contentful_id={productData.product.contentful_id}
          price={productData.product.newPrice}
          fullPrice={productData.product.fullPrice}
          discount={productData.product.discount}
          description={productData.product.longDescription.longDescription}
          images={productData.product.image}
          items={productData.product.items}
        />
        <div className='override-margin'>
          <ProductMarketing first={ productData.marketingOne } second={productData.marketingTwo} third={productData.marketingThree} />
        </div>
      </div>
      {productData.product.extraContent && (
        <>
          <ProductContent
            title={productData.leatherMarketingOne.titleBranding}
            body={productData.leatherMarketingOne.text.text}
            image={
              productData.product.image &&
              productData.product.image[productData.product.image.length - 1]
                .fluid
            }
          />
          <ProductContent
            title={productData.leatherMarketingTwo.titleBranding}
            body={productData.leatherMarketingTwo.text.text}
            image={productData.tuscany.childImageSharp.fluid}
            reversed={true}
          />
        </>
      )}
      <div className="content">
        <FormattedMessage id='product.more-belts'>
          { translation => <ProductShowcase products={ productData.relatedProducts.edges } title={ translation } /> }
        </FormattedMessage>
      </div>
      <ImageHeadline gatsbyImage={ productData.bottomBg.childImageSharp.fluid } />
    </>
  )
}

export default ProductComponent
