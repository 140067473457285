import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { breakpointsDown, colors, spacings } from '../../styling/constants'
import LargeButton from '../LargeButton'

interface Props {
  images: GatsbyTypes.GatsbyImageSharpFluidFragment[]
}

const Wrapper = styled.div`
  display: flex;
`

const Image = styled(BackgroundImage)`
  width: 50%;
  min-height: 35rem;
`

const DoubleImage: React.FC<Props> = ({ images }) => {
  return (
    <Wrapper>
      {images.map((image) => (
        <Image fluid={image} />
      ))}
    </Wrapper>
  )
}

export default DoubleImage
