import React, { Component, useState, useRef, useEffect } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { breakpointsDown } from '../../styling/constants'
import { CarouselArrow } from './CarouselArrow'

import { motion, useSpring, useAnimation } from 'framer-motion'
import CarouselDots from './CarouselDots'

const ProductImage = styled(Img)`
  width: 40vw !important;
  height: 100% !important;
  object-fit: cover !important;
  img {
    -webkit-user-drag: none;
  }
  @media ${breakpointsDown.tablet} {
    width: 90vw !important;
  }
`

const ImageWrapper = styled(motion.div)`
  height: 500px;
  display: flex;
  flex-direction: row;
  cursor: grab;
  width: ${(props) => props.width}vw;

  @media ${breakpointsDown.tablet} {
    height: 350px;
  }
`

const SliderWrapper = styled.div`
  width: 40vw;
  /*border: 10px solid white;*/
  height: 500px;
  overflow: hidden;

  @media ${breakpointsDown.tablet} {
    width: 90vw;
    height: 350px;
  }
`

const EverythingWrapper = styled.div`
  position: relative;
  border: 10px solid white;
`

const DotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 70px;

  @media ${breakpointsDown.tablet} {
    margin-right: 0;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
`

const LeftArrow = styled(Arrow)`
  left: 0rem;
`

const RightArrow = styled(Arrow)`
  right: 0rem;
`

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const swipeConfidenceThreshold = 1000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const ImageCarousel = ({images}) => {
  const breakpoint = 740
  const smallScale = 0.4
  const largeScale = 0.9

  const ref = useRef(null);
  const x = useSpring(0, { stiffness: 300 })
  const controls = useAnimation();

  const bounceStiffness = 100
  const bounceDamping = 10 

  const getInitialScale = () => {
    if (getWindowDimensions().width <= breakpoint) {
      return largeScale
    } else {
      return smallScale
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [scaleConstant, setScaleConstant] = useState(getInitialScale())
  const [xPos, setXPos] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      if ((scaleConstant === smallScale) && (windowDimensions.width <= breakpoint)) {
        setScaleConstant(largeScale)
      } else if (scaleConstant === largeScale && (windowDimensions.width > breakpoint)) {
        setScaleConstant(smallScale)
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    controls.start('toggle')
  }, [xPos])

  useEffect(() => {
    setXPos((windowDimensions.width * scaleConstant) * slideIndex)
  }, [windowDimensions])

  const paginate = (amount) => {
    if ((slideIndex + amount >= 0) && (slideIndex + amount <= (images.length - 1))) {
      setXPos(xPos + (windowDimensions.width * scaleConstant) * amount)
      setSlideIndex(slideIndex + amount)
    }
  }

  return (
    <DotsWrapper>
      <EverythingWrapper>
        <LeftArrow>
          <CarouselArrow direction="left" handleClick={() => paginate(-1)} />
        </LeftArrow>
        <SliderWrapper>
          <ImageWrapper 
            width={images.length * scaleConstant * 100}
            ref={ref}
            drag="x"
            initial={{ x: 0 }}
            animate={controls}
            variants={{
              toggle: { x: -xPos },
            }}
            transition={{
              type: 'spring',
              damping: 40,
              stiffness: 400,
            }}
            dragConstraints={{
              left: -((slideIndex) * windowDimensions.width * scaleConstant),
              right: -(slideIndex * windowDimensions.width * scaleConstant)
            }}
            dragTransition={{ bounceStiffness, bounceDamping }}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
            >
            { images.map(image =>
              <ProductImage className="product-image" fixed={ image.fixed } key={ image.id } />
            ) }
          </ImageWrapper>
        </SliderWrapper>
        <RightArrow>
          <CarouselArrow direction="right" handleClick={() => paginate(1)} />
        </RightArrow>
      </EverythingWrapper>
      <CarouselDots number={images.length} selected={slideIndex} />
    </DotsWrapper>
  )
}

export default ImageCarousel
