import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import { breakpointsDown, spacings } from '../../styling/constants'

interface Props {
  title: string
  body: string
  reversed?: boolean
  image: GatsbyTypes.GatsbyImageSharpFluidFragment
}

interface ReversedProps {
  reversed: boolean
}

const Wrapper = styled.div<ReversedProps>`
  margin: ${spacings.vertical}
    ${({ reversed }) => (reversed ? spacings.horizontal : 0)}
    ${spacings.vertical}
    ${({ reversed }) => (reversed ? 0 : spacings.horizontal)};
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  justify-content: space-between;

  @media ${breakpointsDown.desktop} {
    flex-direction: column;
    margin: 3rem ${spacings.horizontalMobile};
  }
`

const Image = styled(BackgroundImage)`
  width: 60%;
  min-height: 35rem;

  @media ${breakpointsDown.desktop} {
    width: 100%;
    min-height: auto;
    height: 20rem;
  }
`

const ContentText = styled.div<ReversedProps>`
  width: 40%;
  padding: 4rem ${({ reversed }) => (reversed ? 0 : '6rem')} 4rem
    ${({ reversed }) => (reversed ? '6rem' : 0)};
  box-sizing: border-box;
  p {
    opacity: 0.8;
  }

  @media ${breakpointsDown.desktop} {
    width: 100%;
    padding: 0;
  }
`

const ProductContent: React.FC<Props> = ({
  title,
  body,
  image,
  reversed = false,
}) => {
  return (
    <Wrapper reversed={reversed}>
      <ContentText reversed={reversed}>
        <Fade bottom>
          <h3>{title}</h3>
        </Fade>
        <Fade bottom>
          <p>{body}</p>
        </Fade>
      </ContentText>
      <Image fluid={image} />
    </Wrapper>
  )
}

export default ProductContent
